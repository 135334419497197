import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {ModuleAcceessService} from './moduleAccess.service'
@Injectable({
	providedIn: 'root'
})
export class CommonService {
	public loginlayout = { layout: 'login' };
	menu: { isMini: boolean } = { isMini: false };
	showDashboard: boolean = true;

	ckEditorConfig = {
		uiColor: '#F0F3F4',
		height: '350',
		extraPlugins: 'divarea',
		toolbar: [
			{
				name: 'clipboard',
				items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
			},
			{
				name: 'basicstyles',
				items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
			},
			{
				name: 'paragraph',
				items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'CreateDiv', '-', 'Blockquote']
			},
			{
				name: 'justify',
				items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
			},
			{
				name: 'styles',
				items: ['Styles', 'Format', 'FontSize', '-', 'TextColor', 'BGColor']
			}
		]
	};

	constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,private module:ModuleAcceessService) { }

	navigateTo(url: string) {
		this.router.navigate([url]);
	}

	markAsTouchedFormControls(form) {
		Object.keys(form.controls).forEach((field) => {
			const control = form.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}

	resetForm(formGroup: FormGroup) {
		let control: AbstractControl = null;
		formGroup.reset();
		formGroup.markAsUntouched();
		Object.keys(formGroup.controls).forEach((name) => {
			control = formGroup.controls[name];
			control.setErrors(null);
		});
	}
	getBase64(file: Blob) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	deepCopy(value) {
		return value ? JSON.parse(JSON.stringify(value)) : [];
	}

	showSuccess(title, message) {
		this.toastr.success(message, title);
	}

	showInfo(title, message) {
		this.toastr.info(message, title);
	}

	showError(title, message) {
		this.toastr.error(message, title);
	}

	showWarning(title, message) {
		this.toastr.warning(message, title);
	}
	public retrieve(key: string): any {
		return localStorage[key];
	}

	public hasPermission(code: string): boolean {
		const user = this.retrieve('capabilities');
		const userCapabilities = user;
		if (code === null) {
			return true;
		}
		if (userCapabilities && userCapabilities.indexOf(code) > -1) {
			return true;
		} else {
			return false;
		}
	}
	public clubPermission() {
		let clubDetail = []
		const user = this.retrieve('capabilities');
		//let userCapabilities = user;
		const userCapabilitie = user ? user.split(","):[];
		let userAccess = userCapabilitie.reduce((acc, key) => ({ ...acc, [key]: key }), {});
		
		return new Promise((resolve, reject)=>{
			this.getClubDetail().subscribe((res: any) => {
				if (res.data) {
					res.data.forEach((data) => {
						if(data?.club && userAccess.hasOwnProperty(data.club.toLowerCase()+"_club")){
							clubDetail.push(data._id);
						}
						// if (userCapabilities && userCapabilities.indexOf(data.club.toLowerCase()) > -1) {
						// 	clubDetail.push(data._id);
						// }
					})
					 resolve(clubDetail)
				}else {
					reject(false)
				}
			})
		})
		
	}

	public isMenuAccessible(){
		let accessibleMenu = []
		let data = this.module.getModuleInfo();
		if(data.length > 0){
			data.forEach((menu)=>{
				if(this.hasPermission('list_'+menu.name.toLowerCase())){
					accessibleMenu.push(menu)
				}
			})
			return accessibleMenu
		}
	}


	getClubDetail() {
		return this.http.post('/videoListing/clubList',{})
	}
	mapClub(club) {
		let clubName = {
			wolves: 'Wolves',
			epl: "Epl",
			chl: "CHL",
			clubbrugge: "Clubbrugge",
			fulham: "Fulham",
			wolves_u23: "Wolves_U23"
		}

		return clubName[club]
	}

}
